html, body {
  margin: 0;
  padding: 0;
}

div, span, img, a, fieldset, label, legend, form, pre,
p, h1, h2, h3, h4, h5, h6,
table, tbody, tfoot, thead, tr, th, td, dl, dt, dd, ul, ol,
blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  font-size: 100%;
  font-weight: inherit;
}

th, caption {
  text-align: left;
}

input {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}

button {
  font-family: inherit;
  font-weight: inherit;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix {
  zoom: 1;
}
.clearfix:before, .clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}

@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Regular-subset.eot");
  src: local("Segoe UI"), url("fonts/SegoeUI-Regular-subset.woff") format("woff"), url("fonts/SegoeUI-Regular-subset.ttf") format("truetype"), url("fonts/SegoeUI-Regular-subset.svg#SegoeUI") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Light-subset.eot");
  src: local("Segoe UI Light"), url("fonts/SegoeUI-Light-subset.woff") format("woff"), url("fonts/SegoeUI-Light-subset.ttf") format("truetype"), url("fonts/SegoeUI-Light-subset.svg#SegoeUI-Light") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Semibold-subset.eot");
  src: local("Segoe UI Semibold"), url("fonts/SegoeUI-Semibold-subset.woff") format("woff"), url("fonts/SegoeUI-Semibold-subset.ttf") format("truetype"), url("fonts/SegoeUI-Semibold-subset.svg#SegoeUI-Semibold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Italic-subset.eot");
  src: local("Segoe UI Italic"), local("Segoe UI"), url("fonts/SegoeUI-Italic-subset.woff") format("woff"), url("fonts/SegoeUI-Italic-subset.ttf") format("truetype"), url("fonts/SegoeUI-Italic-subset.svg#SegoeUI-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Normal.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Normal.woff") format("woff"), url("fonts/PTRouble-Normal.ttf") format("truetype"), url("fonts/PTRouble-Normal.svg#PTRoubleSans") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Bold.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Bold.woff") format("woff"), url("fonts/PTRouble-Bold.ttf") format("truetype"), url("fonts/PTRouble-Bold.svg#PTRoubleSans") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Italic.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Italic.woff") format("woff"), url("fonts/PTRouble-Italic.ttf") format("truetype"), url("fonts/PTRouble-Italic.svg#PTRoubleSans") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Bold-Italic.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Bold-Italic.woff") format("woff"), url("fonts/PTRouble-Bold-Italic.ttf") format("truetype"), url("fonts/PTRouble-Bold-Italic.svg#PTRoubleSans") format("svg");
  font-weight: bold;
  font-style: italic;
}
.rouble {
  font-family: PTRouble;
  text-transform: uppercase;
}